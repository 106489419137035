import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IResponse } from "../../../interfaces/response.interface";
import { environment } from "../../../../environments/environment";
import { IGetlogInParams } from "./interfaces/geLogInParams.interface";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  logIn(params: IGetlogInParams): Observable<IResponse> {
    const { email, password, type } = params;
    let queryParams = new HttpParams();

    if (email) queryParams = queryParams.set("email", email);
    if (password) queryParams = queryParams.set("password", password);
    if (type) queryParams = queryParams.set("type", type);

    return this.http.get<IResponse>(`${environment.API_DOMAINS.LOGIN}`, {
      params: queryParams,
    });
  }

  tokenRefresh(token: string) {
    let user: any = localStorage.getItem("user");
    user = JSON.parse(user);
    user.token = token;
    user = JSON.stringify(user);
    localStorage.setItem("user", user);
  }
}
