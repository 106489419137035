import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  UpdateProfileDto,
  UpdateProfileAvatarDto,
  CountryResponse,
  UserActionResponse,
  LastActionsResponse,
} from '../interfaces/profile.interface';
import { environment } from '../../../../environments/environment';
import { IResponse } from '../../../interfaces/response.interface';
import { environment_avatar } from '../../../../environments/environment.avatar';

@Injectable({
  providedIn: 'root',
})
export class UserAvatarService {
  private avatarSource = new BehaviorSubject<string>(null);
  private userDataSource = new BehaviorSubject<any>(null);
  environment_avatar = environment_avatar;

  currentAvatar = this.avatarSource.asObservable();
  currentUserData = this.userDataSource.asObservable();

  constructor(private http: HttpClient) {}

  updateProfile(profileData: UpdateProfileDto): Observable<IResponse> {
    try {
      return this.http.post<IResponse>(`${environment.API_DOMAINS.USERS}/update-profile`, profileData);
    } catch (error) {
      console.error(error.status);
    }
  }

  updateProfileAvatar(avatarData: UpdateProfileAvatarDto): Observable<IResponse> {
    try {
      return this.http.post<IResponse>(`${environment.API_DOMAINS.USERS}/update-profile-avatar`, {
        idUser: Number(avatarData.idUser),
        idAvatar: Number(avatarData.idAvatar),
        type: avatarData.type,
      });
    } catch (error) {
      console.error('Error en updateProfileAvatar:', error);
      throw error;
    }
  }

  updateAvatar(avatar: string) {
    try {
      if (!/\.png$/.test(avatar)) {
        avatar = `${avatar}.png`;
      }

      localStorage.setItem('userAvatar', avatar);

      const user = JSON.parse(localStorage.getItem('user') || '{}');

      const updatedUser = {
        ...user,
        avatar: avatar,
        idAvatar: this.extractAvatarId(avatar),
      };

      localStorage.setItem('user', JSON.stringify(updatedUser));

      this.avatarSource.next(avatar);
      this.userDataSource.next(updatedUser);
    } catch (error) {
      console.error('Error en updateAvatar:', error);
    }
  }

  private extractAvatarId(avatarPath: string): number {
    if (!avatarPath || typeof avatarPath !== 'string') {
      return null;
    }

    try {
      const numericMatch = /^(\d+)\.png$/.exec(avatarPath);
      if (numericMatch) {
        return parseInt(numericMatch[1]);
      }

      const avatarMatch = /Avatar(\d+)\.png$/.exec(avatarPath);
      if (avatarMatch) {
        return parseInt(avatarMatch[1]);
      }

      return null;
    } catch (error) {
      console.error('Error al extraer ID del avatar:', error);
      return null;
    }
  }

  getStoredAvatar(): string {
    const storedAvatar = localStorage.getItem('userAvatar');
    const userAvatar = JSON.parse(localStorage.getItem('user') || '{}')?.avatar;
    let avatar = storedAvatar || userAvatar || this.environment_avatar.avatars.default;

    if (/^\d+$/.test(avatar)) {
      avatar = `${avatar}.png`;
    }

    if (!this.environment_avatar.avatars.options.includes(avatar)) {
      return this.environment_avatar.avatars.default;
    }

    return avatar;
  }

  updateUserData(userData: any) {
    try {
      const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
      const updatedUser = {
        ...currentUser,
        ...userData,
        avatar: userData.avatar || currentUser.avatar,
        token: currentUser.token,
        modules: userData.modules || currentUser.modules,
        submodules: userData.submodules || currentUser.submodules,
        idUser: userData.idUser || currentUser.idUser,
        fkClient: currentUser.fkClient,
        type: currentUser.type || 'pc',
      };

      localStorage.setItem('user', JSON.stringify(updatedUser));
      this.userDataSource.next(updatedUser);

      if (userData.avatar) {
        this.avatarSource.next(userData.avatar);
      }
    } catch (error) {
      console.error('Error actualizando datos de usuario:', error);
    }
  }

  private getToken(): string {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user.token || '';
  }

  getCountries(type: string, idCountry: number): Observable<CountryResponse> {
    const params = new HttpParams().set('type', type).set('idCountry', idCountry.toString());

    return this.http.get<CountryResponse>(`${environment.API_DOMAINS.COUNTRY}`, { params });
  }

  getUserActions(type: string, idUser: number): Observable<UserActionResponse> {
    const params = new HttpParams() .set('type', type).set('idUser', idUser.toString());

    return this.http.get<UserActionResponse>(`${environment.API_DOMAINS.USERS}/last-actions`, { params });
  }
}
