<div class="floating-banner bg-dark text-white d-flex align-items-center">
    <div class="scrolling-text" *ngIf="showInfo">
        <ng-container *ngFor="let item of infoList">
            <div class="news-item">
                <span class="news-message">{{ item.message }}</span>
                <span class="news-separator">•</span>
                <span class="news-time">{{ item.timeAgo }}</span>
            </div>
        </ng-container>
    </div>
    <div *ngIf="!showInfo" class="loading-text">
        Cargando información...
    </div>
</div>
  