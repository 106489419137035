import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IResponse } from '../../../../interfaces/response.interface';
import { News } from '../interfaces/news.interface';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(private http: HttpClient) {}

  getNews(type: string): Observable<IResponse<any>> {
    try {
      if (!type || typeof type !== 'string') {
        throw new Error('Type must be a non-empty string');
      }

      return this.http.get<IResponse<News[]>>(`${environment.API_DOMAINS.NEWS}`, {
        params: new HttpParams().set('type', type.trim())
      }).pipe(
        catchError(error => {
          console.error('Error in NewsService:', error);
          throw error;
        })
      );
    } catch (error) {
      console.error('Error in NewsService:', error);
      throw error;
    }
  }

}